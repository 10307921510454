import {createRoot} from 'react-dom/client';

import ApplicationLayout from '@js/components/layouts/application';

require('@js/common');

const root = createRoot(document.getElementById('home-component'));
root.render(
    <ApplicationLayout isHome={true}
                       componentId="home-component"/>
);
